import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import {Link} from "gatsby";
import Footer from "../components/footer";


const serializers = {
    types: {
        code: props => (
            <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
        )
    }
}

const CarBrand = ({pageContext,data}) =>{
    return (
        <>
        <title>Bestill prøvekjøring av {pageContext.brand}</title>
        <header>
            <Link to={"/"}>&lt; Til hele oversikten</Link>
        </header>
        <main>
            {pageContext.image && pageContext.image.asset && pageContext.image.asset.url &&
                <>
                    <img src={pageContext.image.asset.url + "?w=1200"}/>
                    {pageContext.image_text &&
                        <p>{pageContext.image_text}</p>
                    }
                </>
            }

            <h1>Book prøvekjøring av {pageContext.brand}</h1>

            {pageContext.intro &&
                <p>
                    <strong>{pageContext.intro}</strong>
                </p>
            }
            {!pageContext.intro &&
                <p>
                    Under finner du lenke for å bestille testkjøring av ulike modeller fra {' '}
                    <a target="_blank" rel="noreferrer noopener nofollow" href={pageContext.local_url}>{pageContext.brand}</a>.
                </p>
            }

            <a target="_blank" rel="noreferrer noopener nofollow" href={pageContext.test_drive[0].url}>Book prøvekjøring her &raquo;</a>

            {pageContext.content &&
                <BlockContent blocks={pageContext.content} serializers={serializers}/>
            }
        </main>
        <Footer/>
        </>
    )
}

export default CarBrand
